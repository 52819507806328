import React, { useEffect, useState } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useParams, useHistory } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import {
  ShimmerTitle,
  ShimmerThumbnail,
  ShimmerText,
} from 'react-shimmer-effects'
import { useErrorService, useUserService } from '@abroad/components'
import API from '../utils/API'
import SurveyCategoryEvent from '../constants/events'
import { PageHeader } from '../components/common'

const SurveyCategory = ({ t }) => {
  const { category } = useParams()
  let history = useHistory()
  const Error = useErrorService()
  const [isLoading, setIsLoading] = useState(true)
  const [surveyCategory, setSurveyCategory] = useState(null)
  const { user } = useUserService()
  const userId = user?.id

  const gtmOpenSurveyCategoryPageAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: SurveyCategoryEvent.event.surveyCategoryDetails,
        eventProps: {
          category: SurveyCategoryEvent.category.surveyCategoryDetailsCategory,
          action: 'User open survey category details',
          label: 'Open survey category details',
          value: `User open survey category details, category name is ${category}.`,
          userId,
        },
      },
    })
  }

  useEffect(() => {
    const getCategory = async () => {
      try {
        const { data } = await API.survey.getCategory(category)
        setSurveyCategory(data)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
        setTimeout(() => {
          history.replace('/survey')
        }, 3000)
      }
    }
    if (category) {
      getCategory()
      gtmOpenSurveyCategoryPageAction()
    } else {
      history.replace('/survey')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderSpinner = () => {
    if (isLoading) {
      return (
        <section>
          <Row className='mt-2 mt-lg-4'>
            <Col sm={3} className='mt-5'>
              <ShimmerTitle line={1} />
            </Col>
            <Col sm={12} className='category-thumbnail-responsive mt-4'>
              <ShimmerThumbnail />
            </Col>
          </Row>

          {Array.from({ length: 3 }, (_, index) => (
            <>
              <Row className='mt-3 ms-3' key={index}>
                <Col sm={4} className='mt-2'>
                  <ShimmerTitle line={1} />
                </Col>
              </Row>
              <Row className='ms-3'>
                <Col sm={3}>
                  <ShimmerText
                    line={1}
                    gap={20}
                    className='shimmer-text-height'
                  />
                </Col>
              </Row>
              <Row className='ms-3'>
                <Col sm={12}>
                  <ShimmerText line={6} gap={20} />
                </Col>
              </Row>
              <Row className='mt-2 ms-3 bg-color'>
                <Col sm={4} className='mt-4'>
                  <ShimmerTitle line={1} />
                </Col>
              </Row>
              <Row className='ms-3 bg-color'>
                <Col sm={3}>
                  <ShimmerText
                    line={1}
                    gap={20}
                    className='shimmer-text-height'
                  />
                </Col>
              </Row>
              <Row className='ms-3 bg-color'>
                <Col sm={12}>
                  <ShimmerText line={6} gap={20} />
                </Col>
              </Row>
            </>
          ))}
        </section>
      )
    }
    return null
  }

  const renderCategory = () => {
    if (surveyCategory) {
      return (
        <>
          <section>
            <PageHeader
              title='Awareness Assessment'
              version='small'
              backArrowAction={() => {
                if (history.length > 1) {
                  history.goBack()
                } else {
                  history.push('/survey')
                }
              }}
              showBackArrow={true}
            />
            <h3 className='s1 mt-minus-04 mb-32px'>{surveyCategory?.title}</h3>
          </section>
          <section className='bg-gray-300'>
            <Container className='space-y-5 px-lg-5 py-4' fluid>
              <h3 className='text-gray-201 font-montserrat font-semibold'>
                Overview
              </h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: surveyCategory?.description,
                }}
              />
            </Container>
          </section>
          {surveyCategory?.actionItems.length > 0 && (
            <>
              {surveyCategory?.actionItems.map((item, index) => {
                if (index % 2 === 0) {
                  return (
                    <section key={index}>
                      <Container className='space-y-5 px-lg-5 pb-4 pt-5' fluid>
                        {index === 0 && (
                          <h3 className='text-gray-201 font-montserrat font-semibold'>
                            Suggested Practices
                          </h3>
                        )}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item,
                          }}
                        />
                      </Container>
                    </section>
                  )
                }
                return (
                  <section key={index} className='bg-gray-300'>
                    <Container className='space-y-5 px-lg-5 pb-4 pt-5' fluid>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item,
                        }}
                      />
                    </Container>
                  </section>
                )
              })}
            </>
          )}
        </>
      )
    }
    return null
  }

  const renderEmptyContainer = () => {
    if (!surveyCategory && !isLoading) {
      return (
        <section className='h-60 d-flex justify-content-center align-items-center'>
          <h4 className='font-montserrat font-semibold text-dark-600'>
            Please Enter a valid Category
          </h4>
        </section>
      )
    }
    return null
  }

  return (
    <>
      <Helmet>
        <title>{'Survey Category | Abroad'}</title>
        <meta name='title' content={'Survey Category | Abroad'}></meta>
        <meta property='og:title' content={'Survey Category | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Survey Category | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/survey/${category}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/survey/${category}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/survey/${category}`}></meta>
      </Helmet>
      <main>
        {renderSpinner()}
        {renderCategory()}
        {renderEmptyContainer()}
      </main>
    </>
  )
}

export default SurveyCategory

import React from 'react'
import { Col, Row } from 'react-bootstrap'
import {
  ShimmerThumbnail,
  ShimmerBadge,
  ShimmerText,
  ShimmerTitle,
} from 'react-shimmer-effects'

const CustomShimmerContentBlock = () => {
  return (
    <Row className='cursor-pointer border-radius-5 p-32px custom-thumbnail-bg'>
      <Col xl={4} lg={4} md={12} sm={12} className='border-radius-5'>
        <ShimmerThumbnail height={350} className='border-radius-5 w-100 mb-0' />
      </Col>
      <Col xl={8} lg={8} md={12} sm={12} className='pt-3'>
        <ShimmerBadge width={300} />
        <ShimmerTitle line={1} />
        <ShimmerText line={8} gap={15} />
      </Col>
    </Row>
  )
}

export default CustomShimmerContentBlock

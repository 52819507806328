import React, { useState, useEffect } from 'react'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import sysend from 'sysend'
import {
  AuthContext,
  useErrorService,
  NotificationContainerProvider,
  FeatherLoader,
  NotificationProvider,
} from '@abroad/components'
import AuthenticatedRoutes from './AuthenticatedRoutes'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import {
  UnderMaintenance,
  ForceLogout,
  Login,
  Signup,
  ForgotPassword,
  ResetPassword,
  FetchingResult,
  UnsubscribeEmail,
  VerifyEmailSent,
  TwoFactorAuthLogin,
  TwoFactorEmailLogin,
  TwoFactorAuthSetup,
  ErrorView,
} from '../pages'
import { InviteDetailsContext, ProgramContext } from '../utils/contexts'
import ErrorBoundary from '../utils/BugsnagClient'
import AuthService from '../utils/AuthService'
import API from '../utils/API'
import SurveyRequest from '../pages/SurveyRequest'

/**
 * Navigaton routes for the app
 *
 * @info [react-router-dom](https://reactrouter.com/web/guides/quick-start)
 */

const initialState = {
  header: null,
  body: null,
  buttonColorHex: null,
  fontColorHex: null,
  logoFileName: null,
}

const Routes = () => {
  const userData = AuthService.getUserData()
  const [user, setUser] = useState(userData)
  const [programDetails, setProgramDetails] = useState(null)
  const [showEnrollBtn, setShowEnrollBtn] = useState(false)
  const Error = useErrorService()
  const emailVerified = user?.emailVerified
  const planCode = user?.planCode
  const isInIFrame = window.self !== window.top
  const isAdmin = user?.isAdmin
  const isCoach = user?.isCoach
  const isTwoFactorVerified = user?.isTwoFactorVerified
  const userId = user?._id
  const affiliation = user?.affiliation
  const isMatch = isAdmin || isCoach ? isTwoFactorVerified : true
  let history = useHistory()
  let location = useLocation()
  const query = new URLSearchParams(location.search)
  const sowId = query.get('sowId')
  const queryCompanyCode = query.get('c') || query.get('companyCode')
  const queryNetworkCode = query.get('e') || query.get('networkCode')
  const [inviteLinkDetails, setInviteLinkDetails] = useState(initialState)
  const [isLoading, setLoading] = useState(true)
  const [prevLocation, setPrevLocation] = useState(null)
  const [isInviteLinkError, setIsInviteLinkError] = useState(false)

  useEffect(() => {
    if (sowId) {
      localStorage.setItem('sowId', sowId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const getInviteLinkDetails = async () => {
    try {
      const { data } = await API.onboarding.getInviteLinkDetails(
        queryCompanyCode ? 'company' : 'affiliate',
        queryCompanyCode ? queryCompanyCode : queryNetworkCode,
      )
      setInviteLinkDetails({
        ...data?.inviteLink,
        _id: data?._id,
        type: queryCompanyCode ? 'organizations' : 'networks',
      })
      setLoading(false)
    } catch (e) {
      setIsInviteLinkError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (location.pathname === '/') {
      setInviteLinkDetails(initialState)
    }
  }, [location.pathname])

  useEffect(() => {
    if (queryCompanyCode || queryNetworkCode) {
      getInviteLinkDetails()
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryCompanyCode, queryNetworkCode])

  const redirectToHomepage = () => {
    history.push('/home')
  }

  useEffect(() => {
    const getEnrollProgramDetails = async () => {
      try {
        const { data } = await API.user.getEnrollProgramDetails()
        if (data) {
          setProgramDetails(data)
        } else {
          setProgramDetails(null)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    if (emailVerified && affiliation && isMatch) {
      getEnrollProgramDetails()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    planCode,
    affiliation,
    emailVerified,
    isTwoFactorVerified,
    isAdmin,
    isCoach,
  ])

  const getUserPriviliges = async () => {
    try {
      const { data } = await API.user.getUserPriviliges()
      if (data) {
        const profile = data.profile || {}
        const companyId = data.companyId || {}
        delete data.profile
        setUser({
          ...user,
          ...data,
          profile: {
            ...user.profile,
            ...profile,
          },
          companyId: {
            ...(user?.companyId || {}),
            ...companyId,
          },
        })
      }
    } catch (e) {
      Error.showError(e)
    }
  }

  useEffect(() => {
    sysend.on('client_logout', (data) => {
      if (data?.message === 'client_logout') {
        setUser(null)
        history.push('/', {})
      }
    })
    sysend.on('client_signin', (data) => {
      if (data?.message === 'client_signin') {
        const userData = AuthService.getUserData()
        setUser(userData)
        if (!(userData?.isAdmin || userData?.isCoach)) {
          history.push('/home')
        }
      }
    })
    return () => {
      sysend.off('client_logout')
      sysend.off('client_signin')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (emailVerified && !isInIFrame) {
      if (!(isAdmin || isCoach)) {
        if (!window.location.href.includes('user-payment')) {
          getUserPriviliges()
          // getJourneyMetadata()
        }
      } else if (isTwoFactorVerified && (isAdmin || isCoach)) {
        getUserPriviliges()
        // getJourneyMetadata()
      }
    }

    window.addEventListener(
      'message',
      (event) => {
        // Do we trust the sender of this message?
        if (
          event.origin === process.env.REACT_APP_ADMIN_DOMAIN &&
          event?.data === 'remove_iframe'
        ) {
          const iframeRoot = document.getElementById('iframe-root')
          iframeRoot.replaceChildren()
        }
      },
      false,
    )
    return () => {
      window.removeEventListener('message', () => {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailVerified, planCode, isAdmin, isCoach, isTwoFactorVerified, userId])

  // useEffect(() => {
  //   if (!AuthService.isLogedIn()) {
  //     setAuth(false)
  //   }
  // }, [location])

  useEffect(() => {
    setPrevLocation((prev) => {
      if (prev !== null) {
        return prev
      }
      return location.pathname
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    if (
      prevLocation &&
      [
        '/',
        '/2fa-login',
        '/2fa-email',
        '/user-payment',
        '/account',
        '/verify_email',
        '/verify-email-sent',
      ].includes(prevLocation) &&
      user?.isQuestParticipated &&
      !location.pathname.includes('/profile')
    ) {
      history.replace('/your-quest')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevLocation, user, history])

  useEffect(() => {
    let s
    const expires = new Date(null).toUTCString()
    const searchCookie = document.cookie
      ?.split('; ')
      ?.find((row) =>
        row.startsWith(`${process.env.REACT_APP_COOKIE_PREFIX}_search`),
      )
    if (searchCookie) {
      s = searchCookie?.substring(searchCookie?.indexOf('=') + 1)
    }
    const query = new URLSearchParams(s)
    const m = query.get('mode')
    if (m) {
      document.cookie = `${process.env.REACT_APP_COOKIE_PREFIX}_search=;expires=${expires};path=/`
    }
  }, [])

  useEffect(() => {
    if (user) {
      AuthService.setUserData(user)
    }
  }, [user])

  const renderFeatherLoader = () => {
    return (
      <div className='d-flex justify-content-center align-items-center'>
        <FeatherLoader width='60' />
      </div>
    )
  }

  return (
    <ErrorBoundary
      FallbackComponent={() => (
        <ErrorView redirectToHomepage={redirectToHomepage} />
      )}>
      <AuthContext.Provider value={{ user, setUser }}>
        <ProgramContext.Provider
          value={{
            programDetails,
            setProgramDetails,
            showEnrollBtn,
            setShowEnrollBtn,
          }}>
          <NotificationContainerProvider>
            <NotificationProvider>
              <InviteDetailsContext.Provider
                value={{
                  inviteLinkDetails,
                  setInviteLinkDetails,
                  setIsInviteLinkError,
                  isInviteLinkError,
                }}>
                <Switch>
                  {isLoading ? (
                    <React.Fragment>{renderFeatherLoader()}</React.Fragment>
                  ) : (
                    <Route exact path='/account' component={Signup} />
                  )}
                  <Route exact path={`/survey-request`}>
                    <SurveyRequest />
                  </Route>
                  <PublicRoute exact path={'/'} component={Login} />
                  <PublicRoute
                    exact
                    path='/forgot-password'
                    component={ForgotPassword}
                  />
                  <PublicRoute
                    exact
                    path='/password-reset'
                    component={ResetPassword}
                  />
                  <Route
                    exact
                    path='/fetching-result'
                    component={FetchingResult}
                  />
                  <Route
                    exact
                    path='/email/unsubscribe'
                    component={UnsubscribeEmail}
                  />
                  <Route
                    exact
                    path='/transformation/email/unsubscribe'
                    component={UnsubscribeEmail}
                  />
                  {isLoading ? (
                    <React.Fragment>{renderFeatherLoader()}</React.Fragment>
                  ) : (
                    <Route
                      exact
                      path='/verify-email-sent'
                      component={VerifyEmailSent}
                    />
                  )}
                  {/* #TODO-new-design - remove this route after discuss */}
                  <PrivateRoute
                    exact
                    path='/2fa-setup'
                    component={TwoFactorAuthSetup}
                  />
                  <PrivateRoute
                    exact
                    path='/2fa-email'
                    component={TwoFactorEmailLogin}
                  />
                  <PrivateRoute
                    exact
                    path='/2fa-login'
                    component={TwoFactorAuthLogin}
                  />
                  <Route exact path='/force-logout' component={ForceLogout} />
                  <Route
                    exact
                    path='/under-maintenance'
                    component={UnderMaintenance}
                  />
                  <AuthenticatedRoutes />
                </Switch>
              </InviteDetailsContext.Provider>
            </NotificationProvider>
          </NotificationContainerProvider>
        </ProgramContext.Provider>
      </AuthContext.Provider>
    </ErrorBoundary>
  )
}

export default Routes

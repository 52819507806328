import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Imgix from 'react-imgix'
import {
  ShimmerTitle,
  ShimmerThumbnail,
  ShimmerText,
  ShimmerBadge,
  ShimmerButton,
} from 'react-shimmer-effects'
import { Button, useErrorService } from '@abroad/components'
import { PageHeader } from '../common'
import { useResolutionService } from '../../hooks'
import API from '../../utils/API'

const QuestsLearningResourcesBlogDetails = () => {
  const history = useHistory()
  const { blogId } = useParams()
  const Error = useErrorService()
  const { isMDScreen } = useResolutionService()
  const [isLoading, setIsLoading] = useState(true)
  const [blogDetail, setBlogDetail] = useState()

  useEffect(() => {
    const getBlogDetails = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.quests.getParticularBlog(blogId)
        setBlogDetail(data)
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getBlogDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogId])
  return (
    <>
      <Helmet>
        <title>
          {isLoading ? 'Blog Detail' : `${blogDetail?.title} | Abroad`}
        </title>
        <meta
          name='title'
          content={
            isLoading ? 'Blog Detail' : `${blogDetail?.title} | Abroad`
          }></meta>
        <meta
          property='og:title'
          content={
            isLoading ? 'Blog Detail' : `${blogDetail?.title} | Abroad`
          }></meta>
        <meta
          property='twitter:title'
          content={
            isLoading ? 'Blog Detail' : `${blogDetail?.title} | Abroad`
          }></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/quests`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
      </Helmet>
      <PageHeader
        showBackArrow={true}
        version='small'
        title='Your Quest'
        backArrowAction={() => history.goBack()}
      />
      <div>
        {isLoading ? (
          <>
            <div className='mt-40px'>
              <ShimmerTitle line={2} gap={20} />
            </div>
            <div className='mt-52px'>
              <ShimmerText line={4} gap={15} />
            </div>
            <div>
              <ShimmerThumbnail
                className='shimmer-thumbnail-mediaplayer border-radius-10 py-16px'
                rounded
                width={'100%'}
                height={373}
              />
            </div>
            <div className='mt-24px'>
              {Array.from({ length: 5 }).map((_, index) => (
                <div key={index} className='mt-24px'>
                  <ShimmerBadge width={400} />
                  <p>
                    <ShimmerText line={4} gap={15} />
                  </p>
                  <p>
                    <ShimmerText line={4} gap={15} />
                  </p>
                  {index < 4 && <hr />}
                </div>
              ))}
            </div>
            <div className='d-flex justify-content-end'>
              <ShimmerButton size='lg' />
            </div>
          </>
        ) : (
          <>
            <h3 className='s1 mt-24px tracking-normal'>{blogDetail.title}</h3>
            <div className='blog-short-discription pt-16px'>
              {blogDetail.subTitle}
            </div>
            <div className='mt-16px'>
              <div className='mb-16px'>
                <Imgix
                  className='border-radius-10 lazyload imgix-object-cover'
                  imgixParams={{
                    fit: 'crop',
                    crop: 'center',
                    ar: isMDScreen ? '2:1' : '5:2',
                  }}
                  src={`${process.env.REACT_APP_IMG_SOURCE}/blogs/${blogDetail?.image}`}
                  width={'100%'}
                  alt='image'
                  attributeConfig={{
                    src: 'data-src',
                    srcSet: 'data-srcset',
                    sizes: 'data-sizes',
                  }}
                  htmlAttributes={{
                    src: `${process.env.REACT_APP_IMG_SOURCE}/blogs/${
                      blogDetail?.image
                    }?ar=${
                      isMDScreen ? '2:1' : '5:2'
                    }&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format`, // low quality image here
                  }}
                />
              </div>
              <div
                className='blog-details'
                dangerouslySetInnerHTML={{
                  __html: blogDetail.blog,
                }}
              />
            </div>
            <div className='d-flex justify-content-end pt-32px'>
              <Button
                variant='abroad-blue'
                onClick={() => history.go(-1)}
                className='btn s9'>
                Return to Learning Resources
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default QuestsLearningResourcesBlogDetails

import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import {
  ShimmerBadge,
  ShimmerText,
  ShimmerThumbnail,
  ShimmerTitle,
} from 'react-shimmer-effects'
import { Col, Row } from 'react-bootstrap'
import { useUserService, ModalProvider } from '@abroad/components'
import { PageHeader, CustomAssessmentShimmer } from '../common'
import API from '../../utils/API'
import { FreePlanNoSurvey, QuestWisdomResult } from '../Home'
import { surveyType } from '../../utils/constants'
import QuestsFaculty from './QuestsFaculty'
import QuestsLearningResources from './QuestsLearningResources'
import QuestSection from './QuestSections'
import QuestDetail from './QuestDetail'
import { CategoryModal } from '../assessmentResult'

const QuestsYourHome = () => {
  const { user } = useUserService()
  const [questsHome, setQuestsHome] = useState([])
  const questHomeId = questsHome?._id
  const [count, setCount] = useState()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const getCount = async () => {
      try {
        const { data } = await API.quests.getToDoListCount(questHomeId)
        setCount(data)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    if (questHomeId) getCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questHomeId])

  useEffect(() => {
    const getQuestsHome = async () => {
      setIsLoading(true)
      try {
        const { data: quest } = await API.quests.questsHomeGet()
        if (Object.keys(quest).length === 0) {
          const { data: questAll } = await API.quests.allQuest()
          setQuestsHome(questAll[0])
          setIsLoading(false)
        } else {
          setQuestsHome(quest)
          setIsLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }

    getQuestsHome()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const yourQuests = [
    {
      title: 'Clarify Your Intentions',
      imageUrl: `${process.env.REACT_APP_IMG_SOURCE}/Intentions.png`,
      link: questsHome?._id ? `/your-quest/${questsHome?._id}/intentions` : '',
      count: count?.intensionCount > 0 && count?.intensionCount,
    },
    {
      title: 'Review Travel Checklist',
      imageUrl: `${process.env.REACT_APP_IMG_SOURCE}/YourQuestTravelChecklist.png`,
      link: questsHome?._id ? `/your-quest/${questsHome?._id}/todos` : '',
      count: count?.todoListCount > 0 && count?.todoListCount,
    },
    {
      title: 'Review Itinerary',
      imageUrl: `${process.env.REACT_APP_IMG_SOURCE}/TodoList.png`,
      link: questsHome?._id ? `/your-quest/${questsHome?._id}/itinrary` : '',
    },
  ]

  return (
    <>
      <Helmet>
        <title>
          {isLoading ? 'Your Quest' : `${questsHome?.title} | Abroad`}
        </title>
        <meta
          name='title'
          content={
            isLoading ? 'Your Quest' : `${questsHome?.title} | Abroad`
          }></meta>
        <meta
          property='og:title'
          content={
            isLoading ? 'Your Quest' : `${questsHome?.title} | Abroad`
          }></meta>
        <meta
          property='twitter:title'
          content={
            isLoading ? 'Your Quest' : `${questsHome?.title} | Abroad`
          }></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/quests`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
      </Helmet>

      <PageHeader title='Your Quest' />
      {isLoading ? (
        <>
          <div>
            <ShimmerThumbnail
              className='shimmer-thumbnail-mediaplayer border-radius-10 mt-32px'
              rounded
              width={'100%'}
              height={400}
            />
          </div>
          <div className='mt-3'>
            <ShimmerBadge width={120} />
          </div>
          <div>
            <ShimmerTitle line={1} />
          </div>
          <div>
            <ShimmerText line={5} gap={15} />
          </div>
          <div className='mt-5'>
            <Row>
              <Col
                lg={12}
                md={12}
                sm={12}
                className='d-flex justify-content-between'>
                <ShimmerBadge width={200} />
                <ShimmerBadge width={120} />
              </Col>
            </Row>
            <CustomAssessmentShimmer />
          </div>
          <div className='mt-5'>
            <Row>
              <Col
                lg={12}
                md={12}
                sm={12}
                className='d-flex justify-content-between'>
                <ShimmerBadge width={200} />
                <ShimmerBadge width={120} />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col lg={4} md={6} sm={12}>
                <ShimmerThumbnail height={200} rounded />
                <ShimmerBadge width={120} />
                <ShimmerTitle line={1} />
                <ShimmerText line={10} gap={10} />
              </Col>
              <Col lg={4} md={6} sm={12} className='d-none d-md-block'>
                <ShimmerThumbnail height={200} rounded />
                <ShimmerBadge width={120} />
                <ShimmerTitle line={1} />
                <ShimmerText line={10} gap={10} />
              </Col>
              <Col lg={4} md={12} sm={12} className='d-none d-lg-block'>
                <ShimmerThumbnail height={200} rounded />
                <ShimmerBadge width={120} />
                <ShimmerTitle line={1} />
                <ShimmerText line={10} gap={10} />
              </Col>
            </Row>
          </div>
        </>
      ) : !questsHome ? (
        <div className='s5 p-0 mt-3 text-center'>
          No quest available at the moment
        </div>
      ) : (
        <>
          <QuestDetail questHomeId={questHomeId} questHome={questsHome} />
          <div>
            {user.wisdomTaken ? (
              <>
                <ModalProvider>
                  <CategoryModal />
                  <QuestWisdomResult />
                </ModalProvider>
              </>
            ) : (
              <>
                <div className='mt-64px d-flex flex-lg-row flex-column justify-content-lg-between align-items-lg-center'>
                  <div className='s3'>Wisdom Assessment</div>
                </div>
                <FreePlanNoSurvey
                  type={surveyType.WISDOM}
                  isRenderOnAwarenessPage={true}
                  wisdomPage={true}
                  isQuestHomePage={true}
                />
              </>
            )}
          </div>
          <div>
            <QuestsFaculty questId={questsHome?.id} />
          </div>
          <div>
            {yourQuests.map((task, index) => (
              <QuestSection key={index} task={task} />
            ))}
          </div>
          <div>
            <QuestsLearningResources questId={questsHome?.id} />
          </div>
        </>
      )}
    </>
  )
}
export default QuestsYourHome

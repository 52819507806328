import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import {
  ShimmerText,
  ShimmerTitle,
  ShimmerThumbnail,
} from 'react-shimmer-effects'
import moment from 'moment'
import {
  plans,
  useErrorService,
  useUserService,
  leaderAssessmentsUrl,
} from '@abroad/components'
import { BeginningLA, PendingLA, RequestedLA } from '../components'
import API from '../utils/API'
import PrivateRoute from '../routes/PrivateRoute'
import { getAssessmentCurrentData } from '../utils/date'

const LeaderAssessments = () => {
  const url = `/survey/requests/${leaderAssessmentsUrl}`
  const [isLoading, setIsLoading] = useState(true)
  const [hasNotLARequest, setHasNotLARequest] = useState(false)
  const Error = useErrorService()
  const history = useHistory()
  const { id } = useParams()
  const { user } = useUserService()
  const [pages, setPages] = useState([])
  const [canCreateNewLAReq, setCanCreateNewLAReq] = useState(true)

  const getTabData = (data, tabName) => {
    return data.map((data, index) => ({
      path: `${url}/${data._id}`,
      name: tabName
        ? tabName
        : getAssessmentCurrentData(data?.createdAt, 'MMMM Do, YYYY'), // dateValue, format
      id: data._id,
    }))
  }

  useEffect(() => {
    const getLAdata = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.survey.getLeaderRequests()
        setCanCreateNewLAReq(data?.canCreateNewLAReq)
        if (
          data?.pendingRequests?.length === 0 &&
          data?.completedRequests?.length === 0
        ) {
          setHasNotLARequest(true)
          setIsLoading(false)
        } else if (data && data?.completedRequests?.length > 0) {
          const sortedLA = data?.completedRequests?.sort((a, b) => {
            return moment(a.createdAt) > moment(b.createdAt) ? -1 : 1
          })
          history.replace(`/survey/leader/${sortedLA[0]?.id}`)
        } else {
          const updatedPagesData = getTabData(data?.pendingRequests)
          setPages(updatedPagesData)
          setIsLoading(false)
          history.replace(`${url}/pending`)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    if (user?.planCode !== plans.free.code && (id === 'pending' || !id)) {
      getLAdata()
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (isLoading) {
    return (
      <>
        {id === 'pending' ? (
          <>
            <Row className='mt-lg-4'>
              <Col sm={12} md={5} lg={5}>
                <ShimmerThumbnail height={50} />
              </Col>
            </Row>
            <Row>
              <Col sm={5} className='mt-lg-3'>
                <ShimmerTitle line={1} />
              </Col>
              <Col sm={12} className='mt-lg-2'>
                <ShimmerText
                  line={5}
                  gap={20}
                  className='shimmer-text-height'
                />
              </Col>
            </Row>
            <Row className='mt-lg-3'>
              <Col sm={12} md={5} lg={5}>
                <ShimmerThumbnail height={50} />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={5} lg={5}>
                <ShimmerThumbnail height={50} />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className='mt-lg-4'>
              <Col sm={12} md={5} lg={5}>
                <ShimmerThumbnail height={50} />
              </Col>
            </Row>
            <Row>
              <Col sm={5} className='mt-lg-3'>
                <ShimmerTitle line={1} />
              </Col>
              <Col sm={12} className='mt-lg-2'>
                <ShimmerText
                  line={5}
                  gap={20}
                  className='shimmer-text-height'
                />
              </Col>
            </Row>
          </>
        )}
      </>
    )
  }

  return (
    <>
      <PrivateRoute path={`/survey/requests/${leaderAssessmentsUrl}`} exact>
        <BeginningLA hasNotLARequest={hasNotLARequest} />
      </PrivateRoute>
      {id === 'pending' ? (
        <PrivateRoute
          path={`/survey/requests/${leaderAssessmentsUrl}/pending`}
          exact>
          <PendingLA pages={pages} canCreateNewLAReq={canCreateNewLAReq} />
        </PrivateRoute>
      ) : (
        <PrivateRoute
          path={`/survey/requests/${leaderAssessmentsUrl}/:id`}
          exact>
          <RequestedLA />
        </PrivateRoute>
      )}
    </>
  )
}

export default LeaderAssessments

import React, { useState, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { Row, Col } from 'react-bootstrap'
import {
  ShimmerText,
  ShimmerThumbnail,
  ShimmerTitle,
} from 'react-shimmer-effects'
import { useErrorService, useUserService } from '@abroad/components'
import API from '../../utils/API'
import { ProgramModule } from '../../components'
import ContinueProgramEvent from '../../constants/events'
import { useResolutionService } from '../../hooks'

const ContinueYourProgram = () => {
  const Error = useErrorService()
  const { user } = useUserService()
  const { isXSScreen } = useResolutionService()
  const [module, setModule] = useState(null)
  const [isLoadingBreakthrough, setIsLoadingBreakthrough] = useState(true)

  useEffect(() => {
    const getCurrentStatus = async () => {
      try {
        const { data } = await API.programs.getCurrentStatus()
        if (data) {
          setModule(data)
        }
        setIsLoadingBreakthrough(false)
      } catch (e) {
        Error.showError(e)
        setIsLoadingBreakthrough(false)
      }
    }
    getCurrentStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const gtmOpenContinueProgramAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: ContinueProgramEvent.event.openContinueProgram,
        eventProps: {
          category: ContinueProgramEvent.category.openContinueProgramCategory,
          action: 'User open continue program from home page',
          label: 'Open continue program from home page',
          value: `User open continue program from home page, program name is ${module?.title}`,
          userId: user?.id,
        },
      },
    })
  }

  return (
    <>
      <div className='s3 mb-3 mt-32px'>Continue Your Program</div>
      {isLoadingBreakthrough ? (
        <div className='text-center my-2'>
          <div className='custom-thumbnail-bg border-radius-5 px-3 pt-3'>
            <Row>
              <Col lg={3} md={3} sm={3}>
                <ShimmerText line={1} />
              </Col>
            </Row>
            <Row>
              <Col lg={5} md={5} sm={5}>
                <ShimmerTitle line={1} />
              </Col>
            </Row>
            <div
              ref={(ref) => {
                if (ref) {
                  let divider = !isXSScreen ? 3 : 2
                  ref.getElementsByClassName('shimmer')[0].style.height = `${
                    ref.getElementsByClassName('shimmer')[0].offsetWidth /
                    divider
                  }px`
                }
              }}
              className='w-100'>
              <ShimmerThumbnail rounded className='border-radius-5' />
            </div>
          </div>
        </div>
      ) : (
        <ProgramModule
          isLoading={isLoadingBreakthrough}
          program={module}
          programId={module?.parentId}
          onClick={gtmOpenContinueProgramAction}
        />
      )}
    </>
  )
}

export default ContinueYourProgram

import React, { useState, useEffect, useContext } from 'react'
import { Container } from 'react-bootstrap'
import { useErrorService, useUserService } from '@abroad/components'
import { ShimmerTable } from 'react-shimmer-effects'
import TagManager from 'react-gtm-module'
import { LayoutContext } from '../../utils/contexts'
import API from '../../utils/API'
import { mediaModuleTypeObject } from '../../utils/constants'
import { JourneyAudiosListingTable } from '../journey'
import LearningResourceEvent from '../../constants/events'

const QuestsLearningResourcesAudio = ({ questId }) => {
  const {
    setMediaObject,
    setShowPlayBar,
    setContextJourneyId,
    setContextJourneyAudioId,
    setIsJourneyModuleAudio,
    setJourneyAudioType,
    isMediaLoading,
    setIsMediaLoading,
    mediaObject,
  } = useContext(LayoutContext)
  const Error = useErrorService()
  const { user } = useUserService()
  const [mediasList, setMediasList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [journeyId, setJourneyId] = useState()
  const [isToggleFavoriteLoading, setIsToggleFavoriteLoading] = useState(false)
  useEffect(() => {
    const getUserJourneyMedia = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.quests.getQuestJourney(questId)
        if (data) {
          setMediasList(Object.keys(data).length === 0 ? [] : data?.audios)
          setJourneyId(data?._id)
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getUserJourneyMedia()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questId])

  const getMedia = async (
    mediaId,
    mediaSubCategoryId,
    isProgramMedia,
    sequenceId,
  ) => {
    if (isMediaLoading) return
    try {
      setIsMediaLoading(true)
      setShowPlayBar(true)
      let mediaData
      if (isProgramMedia) {
        mediaData = await API.programs.getMediaById(
          mediaSubCategoryId,
          mediaId,
          journeyId,
          sequenceId,
        )
        mediaData = mediaData?.data
      } else {
        mediaData = await API.mediaLibrary.getMediaById(
          mediaSubCategoryId,
          mediaId,
          journeyId,
          sequenceId,
        )
        mediaData = mediaData?.data
      }
      if (mediaData) {
        setContextJourneyId(journeyId)
        setContextJourneyAudioId(mediaData?.journey?.sequenceId)
        setIsJourneyModuleAudio(
          mediaData?.journey?.type === mediaModuleTypeObject?.MODULE,
        )
        setJourneyAudioType(mediaData?.journey?.journeyType)
        setMediaObject(mediaData)
      }
      setIsMediaLoading(false)
    } catch (e) {
      if (e.code === 'upgrade_plan' && !mediaObject) {
        setShowPlayBar(false)
      }
      setIsMediaLoading(false)
      Error.showError(e)
    }
  }

  const getModuleFirstMedia = async (moduleId, sequenceId) => {
    if (isMediaLoading) return
    try {
      setIsMediaLoading(true)
      setShowPlayBar(true)
      const { data } = await API.programs.getModuleFirstMedia(
        moduleId,
        journeyId,
        sequenceId,
      )
      if (data) {
        setContextJourneyId(journeyId)
        setContextJourneyAudioId(data?.journey?.sequenceId)
        setIsJourneyModuleAudio(
          data?.journey?.type === mediaModuleTypeObject?.MODULE,
        )
        setJourneyAudioType(data?.journey?.journeyType)
        setMediaObject(data)
      }
      setIsMediaLoading(false)
    } catch (e) {
      if (e.code === 'upgrade_plan' && !mediaObject) {
        setShowPlayBar(false)
      }
      setIsMediaLoading(false)
      Error.showError(e)
    }
  }

  const getAPIFunction = (
    type,
    mediaOrModuleId,
    subCategoryId,
    toggleFavoriteValue,
  ) => {
    if (type === mediaModuleTypeObject.MODULE) {
      return API.programs.toggleFavorite(
        {
          isFavorite: !toggleFavoriteValue,
        },
        mediaOrModuleId,
      )
    } else if (type === mediaModuleTypeObject.MODULE_MEDIA) {
      return API.programs.favoriteModuleAudio(subCategoryId, mediaOrModuleId, {
        isFavorite: !toggleFavoriteValue,
      })
    } else {
      // for media library audio
      return API.mediaLibrary.toggleFavorite(
        {
          isFavorite: !toggleFavoriteValue,
        },
        mediaOrModuleId,
      )
    }
  }

  const toggleIsFavorite = async (
    type,
    mediaOrModuleId,
    subCategoryId,
    toggleFavoriteValue,
  ) => {
    try {
      setIsToggleFavoriteLoading(true)
      const { status } = await getAPIFunction(
        type,
        mediaOrModuleId,
        subCategoryId,
        toggleFavoriteValue,
      )
      if (status) {
        const updatedMediasList = mediasList.map((media) =>
          media?.mediaOrModuleId === mediaOrModuleId
            ? {
                ...media,
                mediaOrModuleDetail: {
                  ...media?.mediaOrModuleDetail,
                  isFavorite: !toggleFavoriteValue,
                },
              }
            : media,
        )
        setMediasList(updatedMediasList)
      }
      setIsToggleFavoriteLoading(false)
    } catch (e) {
      Error.showError(e)
      setIsToggleFavoriteLoading(false)
    }
  }

  const gtmAudioLearningResource = (mediaId) => {
    //GTM code

    TagManager.dataLayer({
      dataLayer: {
        event: LearningResourceEvent.event.playLRAudio,
        eventProps: {
          category: LearningResourceEvent.category.playLRAudioCategory,
          action: 'User play learning resource audio',
          label: 'Play learning resource audio',
          value: `User play learning resource audio, questId is ${questId}, mediaId is ${mediaId}, journeyId is ${journeyId}`,
          userId: user?._id,
        },
      },
    })
  }

  if (isLoading) {
    return (
      <>
        <div className='mt-40px'>
          <ShimmerTable row={3} col={4} />
        </div>
      </>
    )
  }
  return (
    <>
      <Container fluid className='mt-20px'>
        {mediasList.length === 0 && (
          <h3 className='mt-32px text-center s5'>
            There are no audios available at the moment. Please check back
            later!
          </h3>
        )}
        {mediasList?.length > 0 && (
          <JourneyAudiosListingTable
            mediasList={mediasList}
            getModuleFirstMedia={getModuleFirstMedia}
            playJourneysMediaHandler={gtmAudioLearningResource}
            getMedia={getMedia}
            toggleIsFavorite={toggleIsFavorite}
            isToggleFavoriteLoading={isToggleFavoriteLoading}
            questId={questId}
          />
        )}
      </Container>
    </>
  )
}

export default QuestsLearningResourcesAudio

import { coachRecommends } from '@abroad/components'

export const sowRefId = 'coaching'

export const CONSTANTS = {
  LEADER_360_METADATA: {
    leader: {
      headerText: `Please send this Leader Assessment request to at least 3 direct reports.`,
      description: `You may view your results after 3 responses have been logged. Additional requests may be sent until your results have been made available.`,
      emailMessage: `Dear [Firstname],<br/><br/>[User's Name] is participating in a leadership development program and has requested your valuable insights for their Leader Assessment. Your feedback will contribute to a comprehensive analysis that aids in enhancing their leadership effectiveness and supporting team synergy.<br/><br/>All responses will be shared anonymously in aggregate form alongside all other responses and will not be attributed to you, so please be as honest as possible.<br/><br/>Your perspective is instrumental. By dedicating a few minutes to share your observations, you’re directly contributing to the enrichment and evolution of leadership dynamics within your team.<br/><br/><span class="text-saffron-700 font-bold-700">Take Leader Assessment</span><br/><br/>Thank you for your time and feedback,<br/>[User's Name]`,
    },
    simple360: {
      headerText: `Please send this feedback request to at least 3 people who you want to partake in your simple 360.`,
      description: `Only your coach will be able to see the responses. Once a request has been fulfilled, a green checkmark will appear next to their email.`,
      emailMessage: `Dear [Firstname],<br/><br/>As part of my Abroad coaching program, my coach and I are requesting your feedback on my most important areas for personal and professional development. Your feedback will be sent directly to my coach, not me, so feel free to be honest and candid. Your input will play a crucial role in shaping my growth plan.<br/><br/>My coach will then review your response and integrate your feedback into my overall growth plan. All responses will be shared anonymously and will not be attributed to you.<br/><br/>Here’s the question we’d like you to answer:<br/><br/><b>If I experienced 2-3 breakthroughs in my personal and professional development over the next six months, what would you want that to be? In other words, what are the most important areas for my personal and professional development?</b><br/><br/>Your support means a lot to me, and I truly appreciate your time and insights!<br/><br/><span class="text-saffron-700 font-bold-700">Provide Your Feedback Here</span><br/><br/>Thank you for your time and feedback,<br/>[User's Name]`,
    },
  },
}
// export const participantLevelForManager = [
//   'manager',
//   'leader',
//   'executive',
//   'ceo',
// ]

export const messages = {
  noJourneysFound: `No ${coachRecommends.toLowerCase()} found.`,
  upgradeErrorMsg:
    'You need to upgrade your plan to take advantage of these features.',
}

export const mediaModuleTypeObject = {
  MODULE: 'module',
  MODULE_MEDIA: 'module-media',
  MEDIA_LIBRARY_AUDIO: 'media-library-audio',
}

export const leader360Type = {
  LEADER: 'leader',
  SIMPLE360: 'simple360',
}

export const surveyType = {
  AWARENESS: 'awareness',
  LEADER: 'leader',
  WISDOM: 'wisdom',
}
export const workStatus = {
  OPERATING: 'operating',
  TRANSITION: 'transition',
}

export const selfAwarenessMediaUrl =
  'https://abroad-public.s3.us-east-2.amazonaws.com/01+Self+Awareness.mp3'

export const teamCohesionMediaUrl =
  'https://abroad-public.s3.us-east-2.amazonaws.com/02+Team+Cohesion.mp3'

export const visionImpactMediaUrl =
  'https://abroad-public.s3.us-east-2.amazonaws.com/03+Vision+%26+Impact.mp3'

export const managerEfficacyMediaUrl =
  'https://abroad-public.s3.us-east-2.amazonaws.com/04+Manager+Efficacy.mp3'

export const selfAwarenessPerceptionMediaUrl =
  'https://abroad-public.s3.us-east-2.amazonaws.com/05+Self+Awareness+Perception.mp3'

export const inTransitionSelfAwarenessMediaUrl =
  'https://abroad-public.s3.us-east-2.amazonaws.com/06+Self+Awareness+(Leaders+in+Transition).mp3'

export const nameRegex = /^[A-Za-z\s-]+$/

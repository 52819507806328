import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Row, Col } from 'react-bootstrap'
import { ShimmerThumbnail } from 'react-shimmer-effects'
import { useErrorService, useUserService, plans } from '@abroad/components'
import API from '../utils/API'
import {
  LearnAboutCoaching,
  PageHeader,
  IntentionCard,
} from '../components/common'
import { uuidv4 } from '../utils/utility'
import { ArchivedIntentionsSection } from '../components'

const GrowthPlan = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isUpgradeRequired, setIsUpgradeRequired] = useState(false)
  const [intentions, setIntentions] = useState([])
  const { user } = useUserService()
  const Error = useErrorService()

  useEffect(() => {
    const getintentions = async () => {
      try {
        let { data } = await API.intention.getIntentions({ archived: false })
        setIsUpgradeRequired(data.isUpgradeRequired)
        if (data.intentions.length < 2) {
          setIntentions([
            ...data.intentions,
            ...Array.from({ length: 2 - data.intentions.length }, (x, i) => ({
              _id: uuidv4(),
              isLocal: true,
              title:
                'Click here to create a new intention for generating additional insights',
              createdAt: new Date(),
            })),
          ])
        } else {
          setIntentions([
            ...data.intentions,
            ...Array.from({ length: 1 }, (x, i) => ({
              _id: uuidv4(),
              isLocal: true,
              title:
                'Click here to create a new intention for generating additional insights',
              createdAt: new Date(),
            })),
          ])
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getintentions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderLoading = () => {
    if (isLoading) {
      return (
        <div className='mt-32px'>
          <div className='mt-30px responsive-entries-thumbnail'>
            <Row>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <ShimmerThumbnail rounded />
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <ShimmerThumbnail rounded />
              </Col>
            </Row>
            <Row>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <ShimmerThumbnail rounded />
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <ShimmerThumbnail rounded />
              </Col>
            </Row>
          </div>
        </div>
      )
    }
  }

  const renderBody = () => {
    if (user?.planCode === plans.free.code || isUpgradeRequired) {
      return (
        <section className='mt-32px'>
          <LearnAboutCoaching
            titleFn={() => {
              return (
                <h3 className='s2 text-black mb-0'>
                  Review Your Assessment
                  <br />
                  and Build a Custom Growth Plan
                </h3>
              )
            }}
            detailsText={`As part of Abroad's Growth Plan license, we will hand pick a coach who will help you deep dive into the results of your assessment with the goal of building a custom growth plan for your development. In addition to meeting with a coach, you will also access our full analytics suite and media library. Upgrade today.`}
            buttonText='Inquire about Growth Plan'
            plan={plans.growth.code}
            isDiscardWidth={true}
            imageFilename='learn-more-3.jpg'
            imgCropFP={{
              ar: '4:5',
              'fp-x': 0.51,
              'fp-y': 0,
              auto: 'format',
            }}
            detailsTextClasses='fw-normal'
          />
        </section>
      )
    } else {
      return (
        <>
          <div className='custom-gray-info-text s5 mb-52px mt-32px'>
            Craft your personal growth plan by outlining at least two
            intentions, then enrich them with written or recorded insights as
            they arise.
          </div>
          <div className='s3'>Your Intentions</div>
          {isLoading && renderLoading()}
          {!isLoading && renderIntentions()}
        </>
      )
    }
  }

  const renderIntentions = () => {
    return (
      <>
        <Row>
          {intentions?.map((intention, index) => (
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              key={intention._id}
              className='my-3 px-3'>
              <IntentionCard index={index + 1} intention={intention} />
            </Col>
          ))}
        </Row>
      </>
    )
  }

  return (
    <main>
      <>
        <Helmet>
          <title>{'Growth Plan | Abroad'}</title>
          <meta name='title' content={'Growth Plan | Abroad'}></meta>
          <meta property='og:title' content={'Growth Plan | Abroad'}></meta>
          <meta
            property='twitter:title'
            content={'Growth Plan | Abroad'}></meta>
          <link
            rel='canonical'
            href={`${process.env.REACT_APP_DOMAIN}/coaching/intentions`}></link>
          <meta
            property='og:url'
            content={`${process.env.REACT_APP_DOMAIN}/coaching/intentions`}></meta>
          <meta
            property='twitter:url'
            content={`${process.env.REACT_APP_DOMAIN}/coaching/intentions`}></meta>
        </Helmet>
        <PageHeader title='Growth Plan' />
        {renderBody()}
        <ArchivedIntentionsSection
          setIntentions={setIntentions}
          intentions={intentions}
        />
      </>
    </main>
  )
}

export default GrowthPlan

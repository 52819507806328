import React from 'react'
import classNames from 'classnames'
import { Col, Container, Row } from 'react-bootstrap'
import {
  ShimmerTable,
  ShimmerText,
  ShimmerThumbnail,
} from 'react-shimmer-effects'
import SendRequestInput from './SendRequestInput'
import { useResolutionService } from '../../hooks'

const UserRequestSection = ({
  requestData,
  canViewResult = false,
  canAddMembers = true,
  title,
  description,
  emailBody,
  type,
  simpleDisable,
  setSimpleDisable,
  leaderDisable,
  isLoading,
}) => {
  const { isLGScreen } = useResolutionService()
  if (isLoading) {
    return (
      <>
        <Row>
          <Col lg={8} md={12} sm={12} className='mt-lg-3'>
            <ShimmerText line={2} className='shimmer-text-height mt-lg-3' />
            <ShimmerText line={2} />
            {isLGScreen && (
              <Row className='mt-lg-3'>
                <Col sm={12}>
                  <ShimmerTable row={3} col={2} />
                  <Row className='d-flex justify-content-between'>
                    <Col sm={1}>
                      <ShimmerThumbnail height={50} />
                    </Col>
                    <Col sm={8}>
                      <Row>
                        <Col sm={6}>
                          <ShimmerThumbnail height={50} />
                        </Col>
                        <Col sm={6}>
                          <ShimmerThumbnail height={50} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>

          <Col lg={1} md={12} sm={12}></Col>
          <Col lg={3} md={12} sm={12}>
            <div className='px-0 SL-request-thumbnail'>
              <ShimmerThumbnail />
            </div>
            {!isLGScreen && (
              <Row className='mt-lg-3 mt-4'>
                <Col sm={12}>
                  <ShimmerTable row={3} col={2} />
                  <Row className='d-flex justify-content-between'>
                    <Col sm={1}>
                      <ShimmerThumbnail height={50} />
                    </Col>
                    <Col sm={8}>
                      <Row>
                        <Col sm={6}>
                          <ShimmerThumbnail height={50} />
                        </Col>
                        <Col sm={6}>
                          <ShimmerThumbnail height={50} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </>
    )
  }
  return (
    <Container fluid>
      <Row>
        <Col lg={8} md={12} sm={12} className='px-0'>
          <h3 className='s3 mb-3'>{title}</h3>
          <p className='s5 mb-40px'>{description}</p>
          {isLGScreen && (
            <SendRequestInput
              type={type}
              requestData={requestData}
              canViewResult={canViewResult}
              canAddMembers={canAddMembers}
              simpleDisable={simpleDisable}
              setSimpleDisable={setSimpleDisable}
              leaderDisable={leaderDisable}
            />
          )}
        </Col>
        <Col lg={1} md={12} sm={12}></Col>
        <Col lg={3} md={12} sm={12} className='px-0'>
          <div
            dangerouslySetInnerHTML={{
              __html: emailBody,
            }}
            className={classNames('preview-email border-radius-5', {
              'mb-40px': !isLGScreen,
            })}
          />
          {!isLGScreen && (
            <SendRequestInput
              type={type}
              requestData={requestData}
              canViewResult={canViewResult}
              canAddMembers={canAddMembers}
              simpleDisable={simpleDisable}
              leaderDisable={leaderDisable}
              setSimpleDisable={setSimpleDisable}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default UserRequestSection

import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Imgix from 'react-imgix'
import { Col, Row } from 'react-bootstrap'
import { ShimmerThumbnail } from 'react-shimmer-effects'
import { useErrorService } from '@abroad/components'
import API from '../../utils/API'
import { IntentionCard } from '../common'
import { getAssessmentCurrentData } from '../../utils/date'
import { commonConst } from '../../constants/common'
import { useResolutionService } from '../../hooks'

const RecentNotesAndLogInsight = () => {
  const [sessions, setSessions] = useState([])
  const [isSessionsLoading, setIsSessionsLoading] = useState(true)

  const [intentions, setIntentions] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [isIntentionsLoading, setIsIntentionsLoading] = useState(true)

  const Error = useErrorService()

  useEffect(() => {
    const getRecentSessions = async () => {
      try {
        const { data } = await API.user.getRecentSessions()
        setSessions(data)
        setIsSessionsLoading(false)
      } catch (e) {
        setIsSessionsLoading(false)
        Error.showError(e)
      }
    }
    const getRecentIntentions = async () => {
      try {
        const { data } = await API.user.getRecentIntentions()
        setIntentions([
          ...data.intensions,
          ...Array.from({ length: 2 - data.intensions.length }, (x, index) => ({
            _id: index,
            isLocal: true,
            title:
              'Click here to create a new intention for generating additional insights',
            createdAt: new Date(),
          })),
        ])
        setTotalCount(data?.intensionsCount)
        setIsIntentionsLoading(false)
      } catch (e) {
        setIsIntentionsLoading(false)
        Error.showError(e)
      }
    }
    getRecentSessions()
    getRecentIntentions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (sessions.length === 0) {
    return (
      <div className='container-fluid g-0 mb-52px'>
        <div className='row g-0 justify-content-between'>
          <div className='col-12'>
            <LogInsight
              isLoading={isIntentionsLoading}
              intentions={intentions}
              totalCount={totalCount}
              sessionsEmpty={true}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='container-fluid g-0 mb-52px'>
      <div className='row g-0 justify-content-between'>
        <div className='col-md-5 col-12'>
          <LogInsight
            isLoading={isIntentionsLoading}
            intentions={intentions}
            totalCount={totalCount}
          />
        </div>
        <div className='col-md-5 col-12'>
          <RecentCoachNotes sessions={sessions} isLoading={isSessionsLoading} />
        </div>
      </div>
    </div>
  )
}

const RecentCoachNotes = ({ isLoading, sessions }) => {
  const history = useHistory()

  if (isLoading) {
    return (
      <div className='responsive-entries-thumbnail my-sm-2'>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <ShimmerThumbnail rounded />
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <ShimmerThumbnail rounded />
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className='d-flex flex-lg-row flex-column justify-content-lg-between align-items-lg-center mb-3'>
        <div className='s3'>Recent Coach Notes</div>
        <div
          className='s11 font-saffron-700 cursor-pointer mt-lg-0 mt-2'
          onClick={() => history.push('/coaching/sessions')}>
          Go to Sessions
        </div>
      </div>
      {sessions.map((session, index) => (
        <SessionCard session={session} key={index} />
      ))}
    </React.Fragment>
  )
}

const SessionCard = ({ session }) => {
  const { isLGScreen } = useResolutionService()
  const getImageName = () => {
    const { mediaModules = [], medias = [] } = session
    const r1 = mediaModules.find((e) => e.imageFilename)
    if (r1) {
      return r1.imageFilename
    }
    const r2 = medias.find((e) => e.imageFilename)
    return r2?.imageFilename || commonConst.abroadPlaceholderImage
  }

  const getSessionLabel = () => {
    let label = session.isGrowthSession
      ? 'Growth Plan'
      : session.clientId
      ? 'Individual'
      : 'Group'
    label += ' Session'
    return label
  }

  return (
    <Link
      to={{
        pathname: `/coaching/session/${session?._id}`,
      }}
      className='text-decoration-none text-reset'>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <div className='d-flex'>
          <Imgix
            className='align-self-center border-radius-5'
            imgixParams={{
              crop: 'edges',
              fit: 'crop',
              w: isLGScreen ? 261 : 180,
              h: isLGScreen ? 261 : 180,
            }}
            src={`${process.env.REACT_APP_IMG_SOURCE}/${getImageName()}`}
            height={isLGScreen ? 87 : 60}
            width={isLGScreen ? 87 : 60}
            alt='image'
          />
          <div className='ms-3 d-flex flex-column justify-content-center'>
            <div className='s5 mb-6px'>{getSessionLabel()}</div>
            <div className='s11 opacity-50'>
              {getAssessmentCurrentData(session.createdAt)}
            </div>
          </div>
        </div>
        <div>
          <span className='icon icon-right-arrow font-24 font-black' />
        </div>
      </div>
    </Link>
  )
}

const LogInsight = ({
  isLoading,
  intentions,
  totalCount,
  sessionsEmpty = false,
}) => {
  const history = useHistory()

  if (isLoading) {
    return (
      <div className='responsive-entries-thumbnail'>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <ShimmerThumbnail rounded />
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <ShimmerThumbnail rounded />
          </Col>
        </Row>
      </div>
    )
  }

  const getIndex = (index) => {
    if (totalCount === 0 || totalCount === 1) {
      return index === 0 ? 2 : 1
    }
    return totalCount - index
  }

  return (
    <React.Fragment>
      <div className='d-flex flex-lg-row flex-column justify-content-lg-between align-items-lg-center mb-3'>
        <div className='s3'>Log an Insight</div>
        <div
          className='s11 font-saffron-700 cursor-pointer mt-lg-0 mt-2'
          onClick={() => history.push('/coaching/intentions')}>
          Go to Growth Plan
        </div>
      </div>
      {!sessionsEmpty ? (
        <div className='space-y-4'>
          {intentions.map((intention, index) => (
            <div key={index}>
              <IntentionCard intention={intention} index={getIndex(index)} />
            </div>
          ))}
        </div>
      ) : (
        <div className='row gx-4'>
          {intentions.map((intention, index) => (
            <div className='col-6' key={index}>
              <IntentionCard intention={intention} index={getIndex(index)} />
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  )
}

export default RecentNotesAndLogInsight

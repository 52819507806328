import React from 'react'
import { Container, Table } from 'react-bootstrap'
import Imgix from 'react-imgix'
import TagManager from 'react-gtm-module'
import { isCutomSMScreen } from '../../utils/utility'
import { useUserService } from '@abroad/components'
import { FancyboxPopup } from '../common'
import LearningResourceEvent from '../../constants/events'

const QuestsLearningResourcesVideo = ({ category, questId }) => {
  const { user } = useUserService()
  const shortText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...'
    }
    return text
  }

  const gtmVideoLearningResource = (videoId) => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: LearningResourceEvent.event.playLRVideo,
        eventProps: {
          category: LearningResourceEvent.category.playLRVideoCategory,
          action: 'User play learning resource audio',
          label: 'Play learning resource audio',
          value: `User play learning resource audio, videoId is ${videoId}, questId is ${questId}`,
          userId: user?._id,
        },
      },
    })
  }
  return (
    <>
      <Container fluid className='mt-20px'>
        <Table responsive className='custom-table align-middle table-hover'>
          <thead>
            <tr className='s10a border-dark'>
              {!isCutomSMScreen && <th width='5%'>#</th>}
              <th width={isCutomSMScreen ? '50%' : '40%'}>TITLE</th>
              <th width='50%'>DESCRIPTION</th>
              <th width='15%'>LENGTH</th>
            </tr>
          </thead>
          <tbody>
            {category.map((videoMedia, index) => (
              <tr
                key={index}
                className='cursor-pointer'
                onClick={() => {
                  FancyboxPopup(videoMedia.url)
                  gtmVideoLearningResource(videoMedia._id)
                }}>
                {!isCutomSMScreen && (
                  <td width='5%' className='hover:td-play-icon'>
                    <span>{index + 1}</span>
                    <span className='icon icon-play font-16 icon-outline-gold text-start'></span>
                  </td>
                )}
                <td width={isCutomSMScreen ? '50%' : '40%'}>
                  <div className='d-flex'>
                    <Imgix
                      className='border-radius-5'
                      imgixParams={{
                        crop: 'edges',
                        fit: 'crop',
                        w: 144,
                        h: 144,
                      }}
                      src={`${process.env.REACT_APP_IMG_SOURCE}/quest/${questId}/${videoMedia.thumbnail}`}
                      height={window.innerWidth > 2000 ? 60 : 48}
                      width={window.innerWidth > 2000 ? 60 : 48}
                      alt='image'
                    />
                    <div className='ms-2 media-details d-flex flex-column w-100 align-self-center'>
                      <div className='d-flex'>{videoMedia.title}</div>
                    </div>
                  </div>
                </td>
                <td width='50%'>{shortText(videoMedia.description, 130)}</td>
                <td width='15%'>{videoMedia.timeInMinute} Mins</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  )
}

export default QuestsLearningResourcesVideo

import React, { useContext, useEffect, useState, useMemo } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import Imgix from 'react-imgix'
import { Col, Row } from 'react-bootstrap'
import {
  ShimmerText,
  ShimmerThumbnail,
  ShimmerTitle,
} from 'react-shimmer-effects'
import TagManager from 'react-gtm-module'
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import 'lazysizes/plugins/attrchange/ls.attrchange'
import {
  programIds,
  useUserService,
  plans,
  useErrorService,
} from '@abroad/components'
import imgCrop from '../../constants/imgCrop'
import { LayoutContext, UpgradePlanModalContext } from '../../utils/contexts'
import API from '../../utils/API'
import ModuleMiniDetails from './ModuleMiniDetails'
import ModuleActions from './ModuleActions'
import { useResolutionService } from '../../hooks'
import ProgramEvents from '../../constants/events'

const ProgramModule = React.memo(
  ({
    program,
    isLoading = false,
    programId,
    onClick = () => {},
    // showFavIcon = true, // deprecated
  }) => {
    const [isFavorite, setIsFavorite] = useState(program?.isFavorite)
    const [playIconLoading, setPlayIconLoading] = useState(false)
    const {
      setMediaObject,
      setShowPlayBar,
      setContextJourneyId,
      mediaObject,
      setIsMediaLoading,
    } = useContext(LayoutContext)
    const { isLGScreen, isXSScreen } = useResolutionService()
    const { onShow, setModalData } = useContext(UpgradePlanModalContext)

    const pId = useParams().programId || programId
    const Error = useErrorService()
    const { user } = useUserService()
    const imgCropFP = useMemo(() => {
      return imgCrop[pId] ? imgCrop[pId] : null
    }, [pId])

    useEffect(() => {
      if (program) {
        setIsFavorite(program?.isFavorite)
      }
    }, [program])

    const gtmToggleModuleFavoriteAction = () => {
      //GTM code
      TagManager.dataLayer({
        dataLayer: {
          event: ProgramEvents.event.toggleModuleFavorite,
          eventProps: {
            category: `${program?.title} module like/dislike`,
            action: 'User like/dislike module',
            label: `${program?.title} module like/dislike`,
            value: `isFavorite - ${!isFavorite} for ${program?.title} module`,
            userId: user?._id,
          },
        },
      })
    }

    const toggleIsFavorite = async (e) => {
      try {
        if (program?.isUpgradeRequired) {
          openUpgradePlanModal(e)
          return
        }
        e.preventDefault()
        e.stopPropagation()
        const prevFav = isFavorite
        setIsFavorite((prev) => !prev)
        const { data } = await API.programs.toggleFavorite(
          {
            isFavorite: !isFavorite,
          },
          program?._id,
        )
        if (!data.status) {
          setIsFavorite(prevFav)
        } else {
          gtmToggleModuleFavoriteAction()
        }
      } catch (e) {
        Error.showError(e)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const getModuleFirstMedia = async (e) => {
      try {
        e.preventDefault()
        e.stopPropagation()
        if (playIconLoading) return
        setPlayIconLoading(true)
        setIsMediaLoading(true)
        setShowPlayBar(true)
        const { data } = await API.programs.getModuleFirstMedia(program?._id)
        if (data) {
          setContextJourneyId(null)
          setMediaObject(data)
          setIsMediaLoading(false)
        }
        setPlayIconLoading(false)
      } catch (e) {
        if (e.code === 'upgrade_plan' && !mediaObject) {
          setShowPlayBar(false)
        }
        setPlayIconLoading(false)
        setIsMediaLoading(false)
        Error.showError(e)
      }
    }

    const openUpgradePlanModal = (e) => {
      e.preventDefault()
      e.stopPropagation()
      let header, subHeader, btnText
      switch (pId) {
        case programIds.breakthroughProgramId:
          header = 'Dive Deeper with \nthe Full Breakthrough Program'
          subHeader = `You've tasted the transformative essence of the "Breakthrough" program's introductory module. Ready for more? This experience, masterminded by the former Director of Wellbeing at Yale and the CEO of Abroad, is tailored to redefine the mindsets that could be obstructing your true potential. Delve further to harness fear as a source of creativity, amplify your perception of time, and imbue a growth mindset into every leadership encounter.`
          btnText = 'UNLOCK BREAKTHROUGH'
          break
        case programIds.evolutionProgramId:
          header = 'Explore the \nFull Depth of Evolution'
          subHeader = `You've embarked on a journey with the introductory module of the "Evolution" program. Curious to uncover the rest? Developed by a renowned mindfulness and trauma expert, the subsequent phases invite you to thoroughly understand and nurture your three centers of intelligence. This program will help you befriend your inner critic, temper your triggers, and evolve from reactive to responsive leadership.`
          btnText = 'UNLOCK EVOLUTION'
          break
        case programIds.resilienceProgramId:
          header = 'Unlock Trauma Informed \nLeadership with Expert Coaching'
          subHeader = `Dive deeply into the "Trauma Informed Leadership" program, a curated experience addressing burnout prevention, emotional resilience, and the cultivation of a trauma-informed work culture. Developed to be intimately paired with expert coaching, this program ensures you understand and apply key concepts effectively in real-world scenarios.`
          btnText = 'INQUIRE ABOUT COACHING'
          break
        case programIds.essentialsProgramId:
          header = 'Access the Complete\nLeadership Essentials Toolkit'
          subHeader = `You've explored the importance of purpose and team alignment. Ready for more? This program, crafted by Abroad's CEO, dives into the importance of psychological safety, interpersonal dynamics, and team culture to enhance team cohesion and tackle team dysfunction head on.`
          btnText = 'UNLOCK LEADERSHIP ESSENTIALS'
          break
        default:
          break
      }
      setModalData({
        header,
        subHeader,
        btnText,
        plan: plans.coaching.code,
      })
      onShow()
    }

    if (isLoading) {
      return (
        <div className='custom-thumbnail-bg border-radius-5 px-3 pt-3'>
          <Row>
            <Col lg={3} md={3} sm={3}>
              <ShimmerText line={1} />
            </Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}>
              <ShimmerTitle line={1} />
            </Col>
          </Row>
          <div
            ref={(ref) => {
              if (ref) {
                let divider = !isXSScreen ? 3 : 2
                ref.getElementsByClassName('shimmer')[0].style.height = `${
                  ref.getElementsByClassName('shimmer')[0].offsetWidth / divider
                }px`
              }
            }}
            className='w-100'>
            <ShimmerThumbnail rounded className='border-radius-5' />
          </div>
        </div>
      )
    }

    const renderModuleMiniDetails = () => {
      return (
        <ModuleMiniDetails
          details={{
            label1: `${program?.subTitle?.toLowerCase()}`,
            label2: `${program?.mediaCount} Audios`,
            label3: `${parseInt(program?.totalDuration / 60)} Minutes`,
          }}
          className='align-self-center mb-lg-0 mb-2'
        />
      )
    }

    const renderModuleActions = () => {
      return (
        <ModuleActions
          className='font-24'
          isFavorite={isFavorite}
          toggleIsFavorite={toggleIsFavorite}
          rightMostIcon={
            !program?.isUpgradeRequired ? 'icon-play' : 'icon-padlock'
          }
          handleRightMostIconClick={(e) =>
            !program?.isUpgradeRequired
              ? getModuleFirstMedia(e)
              : openUpgradePlanModal(e)
          }
          playIconLoading={playIconLoading}
        />
      )
    }

    return (
      <NavLink
        to={`/module/${pId}/${program?._id}`}
        className='text-decoration-none d-block'
        onClick={(e) => {
          setContextJourneyId(null)
          if (program?.isUpgradeRequired) {
            openUpgradePlanModal(e)
          } else {
            onClick()
          }
        }}>
        <div className='p-3 border-radius-5 bg-gray-100 program-wrp cursor-pointer position-relative'>
          {/* <div class='z20 cursor-pointer inset-0 position-absolute left-0 top-0 pl-32px'> */}
          <div className='d-lg-flex d-none justify-content-between z20 cursor-pointer mb-12px'>
            {renderModuleMiniDetails()}
            {renderModuleActions()}
          </div>
          <div className='d-lg-none d-block z20 cursor-pointer mb-12px'>
            {renderModuleMiniDetails()}
            {renderModuleActions()}
          </div>
          <div className='d-flex justify-content-between align-items-center mb-2 text-black'>
            <div className='s3'>{program?.title}</div>
          </div>
          <div className='position-relative overflow-hidden breakthrough-media breakthrough-card border-radius-5'>
            <Imgix
              className='lazyload border-radius-5 w-100 h-auto img-cover program-img-zoom'
              imgixParams={{
                fit: 'crop',
                crop:
                  imgCropFP && typeof imgCropFP[program?.subTitle] === 'object'
                    ? 'focalpoint'
                    : 'edges',
                ...(imgCropFP &&
                  typeof imgCropFP[program?.subTitle] === 'object' &&
                  (isLGScreen
                    ? imgCropFP[program?.subTitle]['LGScreen']
                    : imgCropFP[program?.subTitle]['MDScreen'])),
                ...(!isXSScreen && { ar: '3:1' }),
              }}
              width={'100%'}
              src={`${process.env.REACT_APP_IMG_SOURCE}/${program?.imageFilename}`}
              alt='image'
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              htmlAttributes={{
                src: `${process.env.REACT_APP_IMG_SOURCE}/${
                  program?.imageFilename
                }?${!isXSScreen ? 'ar=3:1' : ''}&crop=${
                  imgCropFP && typeof imgCropFP[program?.subTitle] === 'object'
                    ? 'focalpoint'
                    : 'edges'
                }${
                  imgCropFP && typeof imgCropFP[program?.subTitle] === 'object'
                    ? isLGScreen
                      ? `&fp-x=${
                          imgCropFP[program?.subTitle]['LGScreen']['fp-x']
                        }&fp-y=${
                          imgCropFP[program?.subTitle]['LGScreen']['fp-y']
                        }`
                      : `&fp-x=${
                          imgCropFP[program?.subTitle]['MDScreen']['fp-x']
                        }&fp-y=${
                          imgCropFP[program?.subTitle]['MDScreen']['fp-y']
                        }`
                    : ''
                }&fit=crop&fill=blur&blur=300&px=16&auto=format`, // low quality image here
              }}
            />
            {/* <div className='position-absolute color-black inset-0 border-radius-5 hover:bg-white hover:bg-opacity-25'></div> */}
          </div>
        </div>
      </NavLink>
    )
  },
)

export default ProgramModule

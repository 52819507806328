import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Imgix from 'react-imgix'
import { Col, Row } from 'react-bootstrap'
import {
  ShimmerBadge,
  ShimmerButton,
  ShimmerText,
  ShimmerThumbnail,
  ShimmerTitle,
} from 'react-shimmer-effects'
import { useErrorService } from '@abroad/components'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import 'swiper/components/pagination/pagination.scss'
import '../../styles/customSwiper.scss'
import { PageHeader } from '../common'
import API from '../../utils/API'
import { useResolutionService } from '../../hooks'
import UpcomingQuestsFaculty from './UpcomingQuestsFaculty'
import UpcomingQuestsGallery from './UpcomingQuestsGallery'
import UpcomingQuestsInclusionsInvestments from './UpcomingQuestsInclusionsInvestments'
import UpcomingQuestsSignup from './UpcomingQuestsSignup'

const UpcomingQuests = () => {
  const history = useHistory()
  const { isMDScreen } = useResolutionService()
  const { questId } = useParams()
  const Error = useErrorService()
  const [startDay, setStartDay] = useState()
  const [endDay, setEndDay] = useState()
  const [upcomingpublic, setUpcomingPublic] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getpublicQuestsUpcoming = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.quests.publicQuestsUpcomingGet(questId)
        setUpcomingPublic(data)
        setIsLoading(false)
        const getDay = (dateString) => {
          const date = new Date(dateString)
          return date.getUTCDate()
        }
        setStartDay(getDay(data.startDate))
        setEndDay(getDay(data.endDate))
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getpublicQuestsUpcoming()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questId])

  return (
    <div>
      <PageHeader
        showBackArrow={true}
        version='small'
        title={`Bhutan`}
        backArrowAction={() => history.push(`/quests`)}
      />
      <div>
        {isLoading ? (
          <>
            <div className='mt-4'>
              <Row>
                <Col xl={3} lg={4} md={3} sm={4} xs={6}>
                  <ShimmerTitle line={1} />
                </Col>
              </Row>
            </div>
            <div>
              <ShimmerThumbnail
                className='shimmer-thumbnail-mediaplayer border-radius-10 mt-20px'
                rounded
                width={'100%'}
                height={373}
              />
            </div>
            <div className='mt-4'>
              <ShimmerTitle line={2} gap={20} />
            </div>
            <div className='mt-5'>
              <Row className='mt-2'>
                <Col lg={4} md={4} sm={4}>
                  <ShimmerThumbnail height={250} rounded />
                  <ShimmerTitle line={1} />
                  <ShimmerText line={1} />
                </Col>
                <Col lg={4} md={4} sm={4}>
                  <ShimmerThumbnail height={250} rounded />
                  <ShimmerTitle line={1} />
                  <ShimmerText line={1} />
                </Col>
                <Col lg={4} md={4} sm={4}>
                  <ShimmerThumbnail height={250} rounded />
                  <ShimmerTitle line={1} />
                  <ShimmerText line={1} />
                </Col>
              </Row>
            </div>
            <div className='mt-5'>
              <ShimmerThumbnail
                className='shimmer-thumbnail-mediaplayer border-radius-10 mt-20px'
                rounded
                width={'100%'}
                height={400}
              />
            </div>
            <div className='mt-5'>
              <Row>
                <Col lg={6} md={6} sm={6}>
                  <div className='d-flex ms-2'>
                    <ShimmerButton size='lg' />
                    <ShimmerButton size='lg' />
                  </div>
                  <div>
                    <ShimmerText line={7} gap={10} />
                  </div>
                </Col>
                <Col lg={6} md={6} sm={6}>
                  <ShimmerThumbnail height={200} rounded />
                </Col>
              </Row>
            </div>
            <div className='mt-4'>
              <ShimmerBadge width={150} />
              <ShimmerText line={3} gap={10} />
            </div>
            <div className='mt-4'>
              <Row>
                <Col lg={12} md={12} xs={12}>
                  <ShimmerThumbnail height={175} rounded />
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <div>
            <div className='s1 mb-32px mt-lg-0 mt-2'>
              {upcomingpublic.month}&nbsp;{startDay}-{endDay},&nbsp;
              {upcomingpublic.year}
            </div>
            <Imgix
              className='border-radius-10 lazyload imgix-object-cover'
              imgixParams={{
                fit: 'crop',
                crop: 'center',
                ar: isMDScreen ? '2:1' : '5:2',
              }}
              src={`${process.env.REACT_APP_IMG_SOURCE}/quest/${questId}/${upcomingpublic.imageFileName}`}
              width={'100%'}
              alt='image'
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              htmlAttributes={{
                src: `${process.env.REACT_APP_IMG_SOURCE}/quest/${questId}/${
                  upcomingpublic.imageFileName
                }?ar=${
                  isMDScreen ? '2:1' : '5:2'
                }&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format`, // low quality image here
              }}
            />
            <div className='s2 mt-4'>{upcomingpublic.title}</div>

            <div>
              <UpcomingQuestsFaculty
                upcomingpublic={upcomingpublic}
                questId={questId}
              />
              <UpcomingQuestsGallery
                upcomingpublic={upcomingpublic}
                questId={questId}
              />
              <UpcomingQuestsInclusionsInvestments
                upcomingpublic={upcomingpublic}
                questId={questId}
              />
              <UpcomingQuestsSignup questId={questId} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default UpcomingQuests

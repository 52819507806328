import React, { useEffect, useState, useContext } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  ShimmerThumbnail,
  ShimmerTitle,
  ShimmerText,
} from 'react-shimmer-effects'
import {
  useErrorService,
  mediaContent,
  internalMediaTypes,
  coachRecommendsUrl,
  useNotificationService,
} from '@abroad/components'
import ProgramBannerImage from './ProgramBannerImage'
import ParticularAudio from './ParticularAudio'
import NextPrevModuleSection from './NextPrevModuleSection'
import { PageHeader } from '../common'
import API from '../../utils/API'
import { LayoutContext } from '../../utils/contexts'
import ModuleFav from './ModuleFav'
import {
  isLGScreen,
  getJourneyTypePath,
  getProgramName,
  getProgramUrl,
} from '../../utils/utility'
import ModuleMiniDetails from './ModuleMiniDetails'

const ModuleDetails = () => {
  const { setJourneyGroupId, journeyAudioType } = useContext(LayoutContext)
  const [moduleInfo, setModuleInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [rowNumber, setRowNumber] = useState(null)
  let history = useHistory()
  const Error = useErrorService()
  const Notification = useNotificationService()
  let { moduleId } = useParams()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const urlJourneyId = query.get('journeyId')
  const urlJourneyType = query.get('journeyType')
  const urlJourneyGroupId = query.get('journeyGroupId')
  const programName = getProgramName(moduleInfo?.category?.key)
  const programUrl = getProgramUrl(moduleInfo?.category?.key)

  useEffect(() => {
    if (urlJourneyGroupId) {
      setJourneyGroupId(urlJourneyGroupId)
    }
  }, [urlJourneyGroupId, setJourneyGroupId])

  useEffect(() => {
    const getModule = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.programs.getModuleById(moduleId)
        if (data?.isUpgradeRequired) {
          Notification.showNotification(
            'You need to upgrade your plan to take advantage of this feature',
            'danger',
          )
          history.goBack()
        }
        setModuleInfo(data)
        setIsLoading(false)
      } catch (e) {
        if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found' ||
          e?.code === 'media_not_found'
        ) {
          history.replace('/404')
        } else if (e?.code === 'upgrade_plan') {
          setIsLoading(false)
        } else if (e?.code === 'previous_module_not_completed') {
          Error.showError(e)
          history.replace(getModuleSlug())
        } else {
          setIsLoading(false)
          Error.showError(e)
        }
      }
    }
    if (moduleId) {
      getModule()
    } else {
      history.replace('/home')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleId])

  const handleClosePage = () => {
    if (urlJourneyId) {
      history.push(
        `/${coachRecommendsUrl}#${getJourneyTypePath(
          journeyAudioType,
          urlJourneyType,
        )}`,
      )
      return
    } else {
      history.push(programUrl)
    }
  }

  if (isLoading) {
    return (
      <div className='mt-lg-5 mt-sm-3'>
        <Row>
          <Col xl={7} lg={10} md={8} sm={12} className='pt-4'>
            <ShimmerTitle line={1} />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col lg={12} md={12} sm={12}>
            <ShimmerThumbnail
              className='shimmer-thumbnail-mediaplayer border-radius-10'
              rounded
              width={'100%'}
              height={400}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col lg={4} md={4} sm={4}>
            <ShimmerText line={1} gap={10} className='module-content' />
          </Col>
        </Row>
        <ShimmerText line={4} gap={15} className='module-content' />
        <div className='my-16px'>
          {Array.from({ length: 5 }).map((_, index) => (
            <div key={index} className='mt-2 pb-1'>
              {index < 5 && <hr />}
              <Row>
                <Col lg={4} md={4} sm={4}>
                  <ShimmerTitle
                    line={1}
                    variant='primary'
                    className='module-content'
                  />
                </Col>
              </Row>
              <ShimmerText line={2} gap={10} className='module-content' />
            </div>
          ))}
        </div>
      </div>
    )
  }

  const getModuleSlug = () => {
    return programName.toLowerCase()
  }

  const renderHelmet = () => {
    const titleStr = `${programName} Module | Abroad`
    return (
      <Helmet>
        <title>{titleStr}</title>
        <meta name='title' content={titleStr}></meta>
        <meta property='og:title' content={titleStr}></meta>
        <meta property='twitter:title' content={titleStr}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/media/${moduleId}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/media/${moduleId}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/media/${moduleId}`}></meta>
      </Helmet>
    )
  }

  return (
    <Container
      fluid
      className={classnames('bt-module d-flex flex-column overflow-hidden', {
        'px-0': !isLGScreen,
      })}>
      {renderHelmet()}
      <PageHeader
        title={programName}
        version='small'
        backArrowAction={handleClosePage}
        showBackArrow={true}
        wrapperClasses='px-lg-0 px-3'
      />
      <div className='s1 mb-32px px-lg-0 px-3 mt-lg-0 mt-2'>
        {moduleInfo?.title}
      </div>
      <ProgramBannerImage
        bannerImage={moduleInfo?.imageFilename}
        subTitle={moduleInfo?.subTitle}
      />
      <div className='module-content px-lg-0 px-3'>
        <ModuleFav
          isFavorite={moduleInfo?.isFavorite}
          moduleId={moduleId}
          updateModuleInfo={(data) => {
            setModuleInfo((prev) => ({ ...prev, isFavorite: data }))
          }}
          title={moduleInfo?.title}
        />
        <ModuleMiniDetails
          details={{
            label1: moduleInfo?.subTitle?.toLowerCase(),
            label2: moduleInfo?.expert,
          }}
        />
        <div className='s5 mb-32px'>{moduleInfo?.description}</div>
        <div className='mb-52px'>
          {moduleInfo?.medias.map((media, index) => (
            <>
              <div
                key={media?._id}
                className={classnames('hover:bg-gray-100 module-info', {
                  'bg-gray-100 p-32px border-radius-5':
                    media.type === mediaContent.PDF ||
                    media.internalMediaType === internalMediaTypes.POETIC,
                  'first-media-info': index === 0,
                  'border-bottom-transparent': index === rowNumber - 1,
                  'mt-32px border-bottom-none poetic-media-info':
                    media.internalMediaType === internalMediaTypes.POETIC,
                })}
                onMouseEnter={() => {
                  if (media.internalMediaType === internalMediaTypes.POETIC) {
                    return
                  }
                  setRowNumber(index)
                }}
                onMouseLeave={() => {
                  if (media.internalMediaType === internalMediaTypes.POETIC) {
                    return
                  }
                  setRowNumber(null)
                }}>
                <ParticularAudio
                  media={media}
                  programName={programName}
                  source={
                    media.type === mediaContent.PDF ||
                    media.internalMediaType === internalMediaTypes.POETIC
                  }
                />
              </div>
            </>
          ))}
        </div>
        <NextPrevModuleSection
          wrapperClasses={moduleInfo?.poeticAudio ? 'mt-2-5' : ''}
          isPrevModuleDisabled={moduleInfo?.sequence === 0}
        />
      </div>
    </Container>
  )
}

export default ModuleDetails

import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects'

const customThumbnailPair = () => (
  <Row>
    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
      <ShimmerThumbnail rounded />
    </Col>
    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
      <ShimmerThumbnail rounded />
    </Col>
  </Row>
)

const CustomThumbnailShimmer = ({ isComeFromIntention = false }) => {
  return (
    <div className='mt-32px'>
      <div className='responsive-thumbnail'>
        {isComeFromIntention ? (
          <>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <ShimmerTitle line={3} gap={10} />
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <ShimmerThumbnail rounded />
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <ShimmerThumbnail rounded />
            </Col>
          </Row>
        )}
      </div>
      <div className='mt-24px responsive-sub-thumbnail'>
        {customThumbnailPair()}
      </div>
      <div className='mt-30px responsive-entries-thumbnail'>
        {customThumbnailPair()}
        {customThumbnailPair()}
        {customThumbnailPair()}
      </div>
    </div>
  )
}

export default CustomThumbnailShimmer

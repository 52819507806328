import React from 'react'
import {
  ShimmerBadge,
  ShimmerThumbnail,
  ShimmerTitle,
} from 'react-shimmer-effects'
import { Col, Row } from 'react-bootstrap'

const CustomAssessmentShimmer = () => {
  return (
    <Row className='d-flex justify-content-center'>
      <Col
        lg={4}
        md={12}
        sm={12}
        className='d-flex flex-column align-items-center justify-content-center'>
        <div className='assessment-content-wrapper'>
          <ShimmerThumbnail height={250} rounded />
          <ShimmerTitle line={1} />
        </div>
        <ShimmerBadge width={120} />
      </Col>
      <Col
        lg={4}
        md={12}
        sm={12}
        className='d-flex flex-column align-items-center justify-content-center'>
        <div className='assessment-content-wrapper'>
          <ShimmerThumbnail height={250} rounded />
          <ShimmerTitle line={1} />
        </div>
        <ShimmerBadge width={120} />
      </Col>
    </Row>
  )
}

export default CustomAssessmentShimmer

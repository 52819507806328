import React from 'react'
import { Col, Row } from 'react-bootstrap'
import {
  ShimmerButton,
  ShimmerTable,
  ShimmerText,
  ShimmerThumbnail,
} from 'react-shimmer-effects'
const LAResultLoader = () => {
  const ShimmerEffect = () => {
    return (
      <Row className='my-3'>
        <Col sm={11}>
          <ShimmerText line={1} gap={20} className='shimmer-text-height' />
        </Col>
        <Col sm={1}>
          <ShimmerText line={1} gap={20} className='shimmer-text-height' />
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Row>
        <Col sm={5} className='mt-lg-4 mt-3'>
          <ShimmerButton size='md' />
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={6}>
          <div className='d-flex justify-content-center'>
            <ShimmerButton size='md' />
          </div>
          <div className='center'>
            <ShimmerThumbnail height={250} width={300} />
          </div>
          <div className='d-flex justify-content-center'>
            <ShimmerButton size='sm' />
          </div>
        </Col>
        <Col sm={12} lg={3} className='mt-5 mt-lg-0'>
          <div className='d-flex justify-content-center'>
            <ShimmerButton size='md' />
          </div>
          <div className='center'>
            <ShimmerThumbnail height={250} width={300} />
          </div>
          <div className='d-flex justify-content-center'>
            <ShimmerButton size='sm' />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className='LA-result-thumbnail mt-3'>
          <ShimmerThumbnail height={150} />
        </Col>
      </Row>
      <Row>
        <Col sm={12} className=' mt-3'>
          <ShimmerThumbnail height={140} />
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={4}>
          <div className='d-flex justify-content-sm-center'>
            <ShimmerThumbnail height={450} width={250} />
          </div>
        </Col>
        <Col sm={12} lg={8} className='mt-4'>
          <ShimmerButton size='md' />
          <ShimmerEffect />
          <ShimmerEffect />
          <ShimmerEffect />
          <ShimmerEffect />
          <ShimmerEffect />
          <ShimmerEffect />
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col sm={5}>
          <ShimmerThumbnail width={350} height={50} />
        </Col>
        <Col sm={12}>
          <ShimmerText line={4} className='shimmer-text-height' />
          <ShimmerThumbnail height={400} />
          <ShimmerTable row={4} col={6} />
        </Col>
      </Row>
    </>
  )
}
export default LAResultLoader

import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { ShimmerThumbnail } from 'react-shimmer-effects'
import { useErrorService } from '@abroad/components'
import RestoreDeleteIntention from './RestoreDeleteIntention'
import API from '../../utils/API'

const ArchivedIntentionsSection = ({ intentions, setIntentions }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [archivedIntentions, setArchivedIntentions] = useState([])
  const Error = useErrorService()

  useEffect(() => {
    const getintentions = async () => {
      try {
        let { data } = await API.intention.getIntentions({ archived: true })
        if (data) {
          setArchivedIntentions(data.intentions)
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getintentions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSuccess = (intentionId, data) => {
    const index =
      intentions.filter((intention) => intention?.isLocal)?.length === 1 ? 1 : 2
    if (data) {
      const updatedIntentions = [
        // part of the array before the specified index
        ...intentions.slice(0, intentions?.length - index),
        // inserted item
        { ...data, archived: false },
        // part of the array after the specified index
        ...intentions.slice(intentions?.length - index),
      ]
      setIntentions(updatedIntentions)
    }
    setArchivedIntentions((prev) =>
      prev.filter((intention) => intention?._id !== intentionId),
    )
  }

  const renderLoading = () => {
    if (isLoading) {
      return (
        <div className='mt-32px'>
          <div className='mt-30px responsive-entries-thumbnail'>
            <Row>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <ShimmerThumbnail rounded />
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <ShimmerThumbnail rounded />
              </Col>
            </Row>
            <Row>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <ShimmerThumbnail rounded />
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <ShimmerThumbnail rounded />
              </Col>
            </Row>
          </div>
        </div>
      )
    }
  }

  const renderIntentions = () => {
    return (
      <>
        <Row>
          {archivedIntentions?.map((intention, index) => (
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              key={intention._id}
              className='my-3 px-3'>
              <Card className='custom-gray-bg-100 border-0 border-radius-5'>
                <Card.Body className='px-4 py-3 space-y-3'>
                  <Card.Text className='s6 font-black'>
                    {intention?.title}
                  </Card.Text>
                  <div className='d-flex justify-content-between mt-0'>
                    <RestoreDeleteIntention
                      onSuccess={onSuccess}
                      intention={intention}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </>
    )
  }

  return (
    <>
      {archivedIntentions?.length > 0 && (
        <>
          <div className='s3 mt-52px'>Archived Intentions</div>
          {isLoading && renderLoading()}
          {!isLoading && renderIntentions()}
        </>
      )}
    </>
  )
}

export default ArchivedIntentionsSection

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import {
  useUserService,
  useErrorService,
  OverviewWisdomSurveyResults,
  surveyType,
} from '@abroad/components'
import LearnMoreBtnEvent from '../../constants/events'
import FullResultEvent from '../../constants/events'
import API from '../../utils/API'
import { CustomAssessmentShimmer } from '../common'

const type = 'personal'

export default function QuestWisdomResult() {
  const history = useHistory()
  const [QuestSurveyResult, setQuestSurveyResult] = useState()
  const [recentSurveyLoader, setRecentSurveyLoader] = useState(true)
  const [surveyId, setSurveyId] = useState(null)
  const { user } = useUserService()
  const Error = useErrorService()

  useEffect(() => {
    const fetchSurveyId = async () => {
      try {
        setRecentSurveyLoader(true)
        const { data } = await API.survey.getSurveys(type, surveyType.WISDOM)
        if (data) {
          const latestSuveyIndex = data.surveys.length - 1
          setSurveyId(data?.surveys[latestSuveyIndex]?._id)
          setRecentSurveyLoader(false)
        }
      } catch (e) {
        Error.showError(e)
      }
    }

    fetchSurveyId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchSurveyDetails = async () => {
      if (surveyId) {
        try {
          const { data } = await API.survey.getSurveyById(surveyId)
          if (data) {
            setQuestSurveyResult(data)
            setRecentSurveyLoader(false)
          }
        } catch (e) {
          Error.showError(e)
        }
      }
    }
    fetchSurveyDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId])

  const gtmLearnMoreBtnClickedAction = (categoryId) => {
    TagManager.dataLayer({
      dataLayer: {
        event: LearnMoreBtnEvent.event.learnMore,
        eventProps: {
          category: LearnMoreBtnEvent.category.learnMoreCategory,
          action: 'User click on learnMore button',
          label: 'click on learnMore button',
          value: categoryId,
          userId: user?._id,
        },
      },
    })
  }

  const gtmSeeFullResultAction = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: FullResultEvent.event.seeFullResult,
        eventProps: {
          category: FullResultEvent.category.seeFullResultCategory,
          action: 'User click on see full result button',
          label: 'click on see full result button',
          value: 'User click on see full result button',
          userId: user?._id,
        },
      },
    })
  }

  if (recentSurveyLoader) {
    return (
      <div className='mb-52px m-3'>
        <CustomAssessmentShimmer />
      </div>
    )
  }

  if (!QuestSurveyResult) {
    return null
  }

  return (
    <>
      <div className='mt-52px mb-4 d-flex flex-lg-row flex-column justify-content-lg-between align-items-lg-center'>
        <div className='s3'>Wisdom Assessment</div>
        <div
          className='s11 font-saffron-700 cursor-pointer mt-lg-0 mt-2'
          onClick={() => {
            gtmSeeFullResultAction()
            history.push(`/wisdom/${QuestSurveyResult?.id}`)
          }}>
          See Full Results
        </div>
      </div>
      <OverviewWisdomSurveyResults
        survey={QuestSurveyResult}
        onLearnMoreClick={gtmLearnMoreBtnClickedAction}
      />
    </>
  )
}

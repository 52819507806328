import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects'

const CustomSliderShimmer = () => {
  return (
    <>
      <Row>
        <Col xl={3} lg={4} md={4} sm={4} xs={6} className='d-none d-sm-block'>
          <div className='border-radius-10 slider-custom-thumbnail'>
            <ShimmerThumbnail rounded />
            <ShimmerTitle line={2} gap={10} />
          </div>
        </Col>

        <Col xl={3} lg={4} md={4} sm={4} xs={6} className='d-none d-sm-block'>
          <div className='border-radius-10 slider-custom-thumbnail'>
            <ShimmerThumbnail rounded />
            <ShimmerTitle line={2} gap={10} />
          </div>
        </Col>

        <Col xl={3} lg={4} md={4} sm={4} xs={5} className='d-none d-md-block'>
          <div className='border-radius-10 slider-custom-thumbnail'>
            <ShimmerThumbnail rounded />
            <ShimmerTitle line={2} gap={10} />
          </div>
        </Col>

        <Col xl={3} lg={4} md={3} sm={4} xs={5} className='d-none d-xl-block'>
          <div className='border-radius-10 slider-custom-thumbnail'>
            <ShimmerThumbnail rounded />
            <ShimmerTitle line={2} gap={10} />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default CustomSliderShimmer

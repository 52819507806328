import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Imgix from 'react-imgix'
import { LearnAboutCoaching, PageHeader } from '../components/common'
import { Container, Table } from 'react-bootstrap'
import { ShimmerTable } from 'react-shimmer-effects'
import { useErrorService, plans, useUserService } from '@abroad/components'
import { useIntentionReducer } from '../components'
import API from '../utils/API'
import moment from 'moment'
import { commonConst } from '../constants/common'
import { getAssessmentCurrentData } from '../utils/date'

const SessionPractice = () => {
  const { user } = useUserService()

  const renderSessionsComponents = () => {
    if (user.planCode === plans.free.code) {
      return <FreePlanSession />
    }
    if (user.planCode === plans.growth.code) {
      return (
        <>
          <SessionListing />
          <FreePlanSession />
        </>
      )
    }
    return <SessionListing />
  }

  const renderHelmet = () => (
    <Helmet>
      <title>{'Sessions | Abroad'}</title>
      <meta name='title' content={'Sessions | Abroad'}></meta>
      <meta property='og:title' content={'Sessions | Abroad'}></meta>
      <meta property='twitter:title' content={'Sessions | Abroad'}></meta>
      <link
        rel='canonical'
        href={`${process.env.REACT_APP_DOMAIN}/coaching/sessions`}></link>
      <meta
        property='og:url'
        content={`${process.env.REACT_APP_DOMAIN}/coaching/sessions`}></meta>
      <meta
        property='twitter:url'
        content={`${process.env.REACT_APP_DOMAIN}/coaching/sessions`}></meta>
    </Helmet>
  )

  return (
    <>
      {renderHelmet()}
      <main>
        <Container fluid className='px-0 g-0'>
          <PageHeader title='Session' />
          {renderSessionsComponents()}
        </Container>
      </main>
    </>
  )
}
const FreePlanSession = () => {
  return (
    <div className='my-4'>
      <LearnAboutCoaching
        titleFn={() => {
          return (
            <h3 className='s2 text-black mb-0'>
              Your Personalized <br />
              Coaching Space Awaits
            </h3>
          )
        }}
        detailsText={`Step into a personalized coaching experience designed just for you. After beginning your journey with a coach, the ‘sessions’ tab is where they will provide invaluable post-session notes and actionable practices to amplify your growth.`}
        detailsTextClasses='fw-normal'
        plan={plans.coaching.code}
        imageFilename='learn-more-1.jpg'
        imgCropFP={{
          ar: '4:5',
          'fp-x': 0.51,
          'fp-y': 0,
          auto: 'format',
        }}
        buttonText='Inquire about Coaching'
      />
    </div>
  )
}

const SessionListing = () => {
  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()

  const handleRowClick = (sessionId) => {
    history.push(`/coaching/session/${sessionId}`)
  }

  const [individualAndGroupSessions, setIndividualAndGroupSessions] = useState(
    [],
  )

  const Error = useErrorService()
  const { dispatch } = useIntentionReducer()
  useEffect(() => {
    const getSessions = async () => {
      try {
        const { data } = await API.session.getSession()
        const sessionDataFilteredByDate = [
          ...data?.clients,
          ...data?.teams,
        ].sort((a, b) => {
          return moment(a.date) > moment(b.date) ? -1 : 1
        })
        setIndividualAndGroupSessions(sessionDataFilteredByDate)
        setIsLoading(false)
        dispatch({ type: 'POST_SESSION_SUCCESS', payload: null })
      } catch (e) {
        setIsLoading(false)
        if (e?.code === 'upgrade_plan') {
          dispatch({ type: 'POST_SESSION_FAIL', payload: e })
        } else {
          dispatch({ type: 'POST_SESSION_FAIL', payload: null })
          Error.showError(e)
        }
      }
    }
    getSessions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getImageName = (session) => {
    const { mediaModules = [], medias = [] } = session
    const r1 = mediaModules.find((e) => e.imageFilename)
    if (r1) {
      return r1.imageFilename
    }
    const r2 = medias.find((e) => e.imageFilename)
    return r2?.imageFilename || commonConst.abroadPlaceholderImage
  }

  const getTitleBySessionType = (session) => {
    if (session?.isGrowthSession) {
      return 'Growth Plan'
    }
    return !!session?.clientId ? 'Individual' : 'Group'
  }

  return (
    <div className='table-responsive mt-32px'>
      {isLoading ? (
        <ShimmerTable col={4} row={5} />
      ) : (
        <Table className='custom-table align-middle table-hover'>
          <thead>
            <tr className='s10a border-dark'>
              <th width='7%'></th>
              <th width='27%'>DATE</th>
              <th width='27%'>SESSION TYPE</th>
              <th width='27%'>COACH</th>
              <th width='12%' className='text-end'>
                NOTES
              </th>
            </tr>
          </thead>
          {individualAndGroupSessions.length === 0 && (
            <tr>
              <td className='text-center s8 py-3' colSpan='5'>
                No sessions found.
              </td>
            </tr>
          )}
          <tbody>
            {individualAndGroupSessions.length > 0 &&
              !isLoading &&
              individualAndGroupSessions.map((session, index) => (
                <tr
                  key={index === 0}
                  className='cursor-pointer'
                  onClick={() => handleRowClick(session._id)}>
                  <td width='7%' className='ps-0'>
                    <div>
                      <Imgix
                        className='border-radius-5'
                        imgixParams={{
                          crop: 'edges',
                          fit: 'crop',
                          w: 144,
                          h: 144,
                        }}
                        src={`${
                          process.env.REACT_APP_IMG_SOURCE
                        }/${getImageName(session)}`}
                        height={48}
                        width={48}
                        alt='image'
                      />
                    </div>
                  </td>
                  <td width='27%'>
                    {getAssessmentCurrentData(session?.date, 'MMMM Do YYYY')}
                  </td>
                  <td width='27%'>{getTitleBySessionType(session)}</td>
                  <td width='27%'>{session?.coachId?.fullName}</td>
                  <td width='12%' className='text-end'>
                    <div className='pe-2 text-saffron-700 hover'>View</div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </div>
  )
}

export default SessionPractice

import React, { useEffect, useState } from 'react'
import { useErrorService } from '@abroad/components'
import { UserRequestSection } from '../leaderSimple360'
import { CONSTANTS, leader360Type } from '../../utils/constants'
import API from '../../utils/API'

const Simple360Request = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [requestData, setRequestData] = useState([])
  const [simpleDisable, setSimpleDisable] = useState(true)
  const Error = useErrorService()
  useEffect(() => {
    const getRequestedUsers = async () => {
      try {
        const { data } = await API.survey.getRequestedUsers()
        if (data) {
          setRequestData(data)
          setIsLoading(false)
          setSimpleDisable(data.length === 0)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getRequestedUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className='mt-32px simple-360'>
      <UserRequestSection
        requestData={requestData}
        title={CONSTANTS.LEADER_360_METADATA.simple360.headerText}
        description={CONSTANTS.LEADER_360_METADATA.simple360.description}
        emailBody={CONSTANTS.LEADER_360_METADATA.simple360.emailMessage}
        type={leader360Type.SIMPLE360}
        simpleDisable={simpleDisable}
        setSimpleDisable={setSimpleDisable}
        isLoading={isLoading}
      />
    </section>
  )
}

export default Simple360Request
